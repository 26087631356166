<template>
  <el-popconfirm title="你确定要执行删除操作吗?" @confirm="confirm()">
    <template #reference>
      <el-button type="danger" text>删除</el-button>
    </template>
  </el-popconfirm>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'

const props = defineProps({
  name: {
    type: String,
    default: '',
    required: false
  }
})

const emit = defineEmits(['confirm'])
const confirm = () => {
  emit('confirm')
}
</script>
