const getters = {
  adminInfo: state => state.user.adminInfo,
  loadMenu: state => state.user.loadMenu,
  accessToken: state => state.user.accessToken,
  routers: state => state.user.routers,
  apiList: state => state.user.apiList,
  openMenu: state => state.app.openMenu,
  device: state => state.app.device
}
export default getters
