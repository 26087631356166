import router from './router'
import pageTitle from './utils/tools/pagesTitle'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style

const whiteList = ['/login'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  // set page title
  document.title = pageTitle(to.meta.title)
  // start progress bar
  NProgress.start()
  const accessToken = store.state.user.accessToken
  // 判断登录状态
  if (accessToken) {
    if (to.name === 'login') {
      next({ path: '/' })
    } else {
      const loadMenu = store.getters.loadMenu
      if (loadMenu) {
        if (!to.matched.length) {
          router.push(to.path)
        }
        next()
      } else {
        await store.dispatch('user/info').then(res => {
          const asyncRouter = res
          asyncRouter.forEach(v => {
            router.addRoute(v)
          })
          // 如果 addRoute 并未完成，路由守卫会一层一层的执行执行，直到 addRoute 完成，找到对应的路由
          store.dispatch('user/loadMenu', true)
          next({ ...to, replace: true })
        })
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      next(`/login?redirect=${to.path}`)
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})

