import { login, info } from '@/utils/apis/system/admins'
import * as Cache from '@/utils/tools/cache'
import Layout from '@/layout'
import { constantRoutes } from '@/router'
import router from '@/router'

const state = () => ({
  accessToken: Cache.get('accessToken') || '',
  adminInfo: {},
  apiList: [],
  routers: constantRoutes,
  loadMenu: false
})

const mutations = {
  SET_ACCESS_TOKEN: (state, token) => {
    if (token) {
      Cache.set('accessToken', token)
    } else {
      Cache.del('accessToken')
    }
    state.accessToken = token
  },
  SET_ADMIN_INFO: (state, adminInfo) => {
    state.adminInfo = adminInfo
  },
  SET_API_LIST: (state, apiList) => {
    state.apiList = apiList
  },
  SET_ROUTERS: (state, routers) => {
    state.routers = routers
  },
  SET_LOAD_MENUS: (state, hasLoadMenu) => {
    state.loadMenu = hasLoadMenu
  }
}

const actions = {
  login({ commit }, loginData) {
    return new Promise((resolve, reject) => {
      login(loginData).then(response => {
        commit('SET_ACCESS_TOKEN', response.token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  logout({ commit }) {
    commit('SET_ACCESS_TOKEN', '')
    commit('SET_ADMIN_INFO', '')
  },
  loadMenu({ commit }) {
    commit('SET_LOAD_MENUS', true)
  },
  info({ commit }) {
    return new Promise((resolve, reject) => {
      info().then(res => {
        commit('SET_ADMIN_INFO', res.userInfo)
        commit('SET_API_LIST', res.apis)
        const systemRoutes = [...constantRoutes]
        generaMenu(systemRoutes, res.routes)
        systemRoutes.push({
          path: '/:pathMatch(.*)*',
          hidden: true,
          component: () => import('@/views/error-page/404')
        })
        commit('SET_ROUTERS', systemRoutes)
        resolve(systemRoutes)
      }).catch(() => {
        commit('SET_ACCESS_TOKEN', '')
        commit('SET_ADMIN_INFO', '')
        router.push(`/login`)
      })
    })
  }
}

/**
 * 后台查询的菜单数据拼装成路由格式的数据
 * @param routers
 */
const generaMenu = (constantRouters, routers) => {
  routers.forEach(item => {
    const menu = {
      path: item.path,
      component: item.component === 'Layout' ? Layout : () => import(`@/views${item.component}`),
      children: [],
      name: item.name,
      hidden: item.is_show === 0,
      meta: { title: item.title, icon: item.icon, id: item.id }
    }
    if (item.redirect) {
      menu.redirect = item.redirect
    }
    if (item.children) {
      generaMenu(menu.children, item.children)
    }
    constantRouters.push(menu)
  })
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
