import { createRouter, createWebHistory } from 'vue-router'
import Layout from '@/layout'

export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/auth/login'),
    hidden: true,
    name: 'login',
    meta: { title: '登录' }
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/error-page/404'),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    hidden: false,
    name: 'Index',
    meta: { title: '首页', icon: 'dashboard' },
    children: [
      {
        path: 'home',
        component: () => import('@/views/index'),
        meta: { title: '平台首页' },
        hidden: true
      }
    ]
  },
  {
    path: '/admins',
    component: Layout,
    redirect: '/setting',
    hidden: true,
    children: [
      {
        path: 'setting',
        component: () => import('@/views/system/adminSetting'),
        name: 'admins.setting',
        meta: { title: '个人信息', icon: 'dashboard' },
        hidden: true
      }
    ]
  },
  {
    path: '/',
    name: 'infomation',
    component: Layout,
    hidden: false,
    meta: { title: '信息管理', icon: 'infomation' },
    children: [
      {
        path: '/skill',
        name: 'infomation.skill',
        component: () => import('@/views/skill'),
        meta: { title: '熟练技能', icon: 'skill' },
        hidden: false
      },
      {
        path: '/project',
        name: 'infomation.project',
        component: () => import('@/views/project'),
        meta: { title: '项目管理', icon: 'project' },
        hidden: false
      },
      {
        path: '/company',
        name: 'infomation.company',
        component: () => import('@/views/company'),
        meta: { title: '工作经验', icon: 'company' },
        hidden: false
      }
    ]
  }
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
const router = createRouter({
  routes: constantRoutes,
  history: createWebHistory(),
  scrollBehavior() {
    // 始终滚动到顶部
    return { top: 0 }
  }
})

export const resetRouter = () => {
  const newRouter = createRouter()
  router.resolve = newRouter.resolve
}

export default router
