import Clipboard from 'clipboard'
import { ElMessage } from 'element-plus'

const editData = (infoData, formData, key = 'id') => {
  const diffData = {}
  for (var k in formData) {
    if (typeof infoData[k] === 'object') {
      if (JSON.stringify(infoData[k]) !== JSON.stringify(formData[k])) {
        diffData[k] = formData[k]
      }
    } else {
      if (formData[k] !== infoData[k]) {
        diffData[k] = formData[k]
      }
    }
  }

  if (!emptyObject(diffData)) diffData[key] = infoData[key]

  return diffData
}

const emptyObject = obj => Object.keys(obj).length === 0

/**
 * @param {Object} initData
 * @param {Object} data
 * @returns {Object}
 * 组合编辑表单数据
*/
export const combiningEditData = (initData, data) => {
  const editData = {}
  Object.keys(initData).forEach((key) => {
    if (typeof data[key] !== 'undefined') {
      editData[key] = data[key]
    }
  })
  return editData
}

export const openLink = (url) => {
  window.open(url, '_blank')
}

const empty = value => {
  return value === null || value === 'null' || value === '' || value === undefined
}

const toUrlParams = params => {
  let str = ''
  Object.keys(params).map(key => {
    if (!empty(params[key])) {
      str += key + '=' + params[key] + '&'
    }
  })
  return '?' + str.substr(0, str.length - 1)
}

const queryToParams = (query, params) => {
  var newParams = {}
  for (var i in params) {
    if (query[i] !== undefined) {
      newParams[i] = isNaN(Number(query[i])) ? query[i] : Number(query[i])
    } else {
      newParams[i] = params[i]
    }
  }
  return newParams
}

const moneyTo = (money, num = 2) => {
  return money > 9999 ? (money / 10000).toFixed(num) + 'W' : money
}

const filePreviewUrl = (fileUrl, suffix) => {
  console.log(suffix)
  if (['pdf', 'jpg', 'png', 'jpeg', 'gif'].includes(suffix)) {
    return fileUrl
  } else {
    return '//view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(fileUrl)
  }
}

const getBeforeDate = number => {
  const num = number
  const date = new Date()
  let year = date.getFullYear()
  let mon = date.getMonth() + 1
  let day = date.getDate()
  if (day <= num) {
    if (mon > 1) {
      mon = mon - 1
    } else {
      year = year - 1
      mon = 12
    }
  }
  date.setDate(date.getDate() - num)
  year = date.getFullYear()
  mon = date.getMonth() + 1
  day = date.getDate()
  const s = year + '-' + (mon < 10 ? ('0' + mon) : mon) + '-' + (day < 10 ? ('0' + day) : day)
  return s
}

const getDay = () => {
  const date = new Date()
  var year = date.getFullYear()
  var mon = date.getMonth() + 1
  var day = date.getDate()

  const s = year + '-' + (mon < 10 ? ('0' + mon) : mon) + '-' + (day < 10 ? ('0' + day) : day)
  return s
}

const requireImg = path => {
  return require(`@/assets/imgs/${path}`)
}

const copy = (text, event, title) => {
  const clipboard = new Clipboard(event.target, {
    text: () => text
  })
  clipboard.on('success', () => {
    ElMessage({
      message: title + '已复制到粘贴板！',
      duration: 2000,
      type: 'success'
    })
    clipboard.destroy()
  })
  clipboard.on('error', () => {
    ElMessage({
      message: title + '复制失败',
      duration: 2000,
      type: 'error'
    })
    clipboard.destroy()
  })
  clipboard.onClick(event)
}

export default {
  editData,
  emptyObject,
  openLink,
  empty,
  toUrlParams,
  queryToParams,
  combiningEditData,
  moneyTo,
  filePreviewUrl,
  getBeforeDate,
  getDay,
  requireImg,
  copy
}
