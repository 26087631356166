<template>
  <div class="rollBox">
    <div v-if="notifyList.length > 0" class="marquee">
      <p v-for="(item, index) in notifyList" :key="index" class="font-item">{{ item.content }}</p>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const notifyList = ref([])

</script>

<style lang="scss">
.rollBox {
  width: 100%;
  height: 50px;
  line-height: 50px;
  overflow: hidden;
  .marquee {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    /* @keyframes 动画指定名称。 */
    animation-name: marquee-left;
    /* 定义动画完成一个周期需要多少秒或毫秒 */
    animation-duration: 32s;
    /* 速度曲线 */
    animation-timing-function: linear;
    /* 定义动画应该播放多少次 */
    animation-iteration-count: infinite;
    .font-item {
      color: #fff;
      font-size: 16px;
      margin-right: 20px;
    }
  }
}

// 从右向左
@keyframes marquee-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
