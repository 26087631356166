import request from '@/utils/apis/request'

export const loginRules = {
  loginname: [{ required: true, message: '请输入手机号！', trigger: 'blur' }],
  password: [{ required: true, message: '请输入登录密码！', trigger: 'blur' }]
}

export const adminRules = {
  loginname: [{ required: true, message: '请输入账号登录名！', trigger: 'blur' }],
  username: [{ required: true, message: '请输入管理员姓名！', trigger: 'blur' }],
  mobile: [{ required: true, message: '请输入手机号！', trigger: 'blur' }],
  avatar: [{ required: true, message: '请上传用户头像！', trigger: 'blur' }],
  rid: [{ required: true, message: '请选择用户权限！', trigger: 'blur' }]

}

export const login = (data) => {
  return request({
    method: 'post',
    data: data,
    url: '/backendApi/auth'
  })
}

export const info = () => {
  return request({
    method: 'post',
    url: '/backendApi/info'
  })
}

export const list = data => {
  return request({
    method: 'post',
    url: '/backendApi/admins/list',
    params: data
  })
}

export const add = data => {
  return request({
    method: 'post',
    url: '/backendApi/admins/add',
    data: data
  })
}

export const edit = data => {
  return request({
    method: 'post',
    url: '/backendApi/admins/edit',
    data: data
  })
}

export const setting = data => {
  return request({
    method: 'post',
    url: '/backendApi/admins/setting',
    data: data
  })
}

export const del = id => {
  return request({
    method: 'post',
    url: '/backendApi/admins/del',
    data: {
      id
    }
  })
}
