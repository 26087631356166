<template>
  <div class="container" :class="{ closeMenu: !state.app.openMenu }">
    <div class="top-header">
      <div class="log-icon">
        <span>{{ title }}</span>
      </div>
      <div class="search-tips">
        <MarqueeVue />
      </div>
      <div class="header-right">
        <div class="icons">
          <!-- <div class="icon-item" @click="toScreenFull">
            <el-tooltip
              class="box-item"
              effect="dark"
              content="在线客服"
              placement="top-start"
            >
              <el-icon :size="18" color="#ffffff"><Service /></el-icon>
            </el-tooltip>
          </div> -->
          <div class="icon-item" @click="toScreenFull">
            <el-tooltip
              class="box-item"
              effect="dark"
              content="全屏展示"
              placement="top-start"
            >
              <el-icon :size="18" color="#ffffff"><FullScreen /></el-icon>
            </el-tooltip>
          </div>
          <div class="icon-item message">
            <!-- <span class="number">5</span> -->
            <el-tooltip
              class="box-item"
              effect="dark"
              content="系统消息"
              placement="top-start"
            >
              <el-icon :size="18" color="#ffffff"><BellFilled /></el-icon>
            </el-tooltip>
          </div>
        </div>
        <el-dropdown trigger="click">
          <div class="company-user el-dropdown-link">
            <el-avatar shape="square" :size="34" src="https://oss.phpchao.com/images/admin/system/image/8daad7eb7d14e0fd8c3e17dc99f2291a.png" />
            <div class="icon">
              <el-icon :size="20"><CaretBottom /></el-icon>
            </div>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :icon="Tools" @click="passwordReset">修改密码</el-dropdown-item>
              <el-dropdown-item divided :icon="SwitchButton" @click="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <div class="layout-content">
      <div class="menu-container">
        <div class="menu-header">
          <div class="left">
            <el-avatar shape="square" :size="36" src="https://oss.phpchao.com/images/admin/system/image/8daad7eb7d14e0fd8c3e17dc99f2291a.png" />
          </div>
          <div class="right">
            <div class="company-name">{{ state.user.adminInfo.username }}</div>
            <div class="company-auth">
              <span>超级管理员</span>
            </div>
          </div>
        </div>
        <el-scrollbar height="calc(100vh - 120px)">
          <div class="menu-list">
            <Menu />
          </div>
        </el-scrollbar>
      </div>
      <div class="main-container">
        <TopHeader />
        <div class="main-content">
          <router-view v-slot="{ Component, route}">
            <component :is="Component" :key="route.fullPath" />
          </router-view>
        </div>
      </div>
    </div>
    <el-drawer v-model="resetPasswordVisible" :size="540">
      <template #header>
        <h4>重置登录密码</h4>
      </template>
      <template #default>
        <el-form
          ref="formRef"
          :model="resetPasswordData"
          :rules="resetPasswordRules"
          label-width="120px"
          class="login-form"
          status-icon
          @keydown.enter="passwordResetConfirm(formRef)"
        >
          <el-form-item prop="phone" label="手机号：">
            <el-input v-model="resetPasswordData.phone" :disabled="true" />
          </el-form-item>
          <el-form-item prop="oldPassword" label="原密码：">
            <el-input v-model="resetPasswordData.oldPassword" type="password" placeholder="请输入原登录密码！" />
          </el-form-item>
          <el-form-item prop="password" label="新密码：">
            <el-input v-model="resetPasswordData.password" type="password" placeholder="请输入登录密码！" />
          </el-form-item>
          <el-form-item prop="password_confirmation" label="确认密码：">
            <el-input v-model="resetPasswordData.password_confirmation" type="password" placeholder="请再次确认登录密码！" />
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div style="flex: auto">
          <el-button @click="resetPasswordVisible = false">取消</el-button>
          <el-button type="primary" @click="passwordResetConfirm(formRef)">
            提交
          </el-button>
        </div>
      </template>
    </el-drawer>
  </div>
</template>

<script setup>

import Menu from './components/Menu.vue'
import TopHeader from './components/TopHeader.vue'
import { useStore } from 'vuex'
import { inject, onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { CaretBottom, BellFilled, FullScreen, Service, Tools, SwitchButton } from '@element-plus/icons-vue'
import ScreenFull from 'screenfull'
import msg from '@/utils/tools/msg'
import MarqueeVue from './components/Marquee.vue'
import helper from '@/utils/helper'

onMounted(() => {
  window.onresize = () => {
    setDevice()
  }
})

const title = ref(process.env.VUE_APP_NAME)

const toScreenFull = () => {
  ScreenFull.toggle()
}

const { state, dispatch } = useStore()
console.log(state.user.adminInfo)
const minWidth = 875
const setDevice = () => {
  const clientWidth = document.body.clientWidth
  const isMobile = clientWidth <= minWidth
  dispatch('app/toggleDevice', isMobile ? 'mobile' : 'desktop')
  // 移动设备关闭菜单
  if (isMobile && state.app.openMenu) dispatch('app/menuTagger')
  // 桌面设备展开
  if (!isMobile && !state.app.openMenu) dispatch('app/menuTagger')
}

setDevice()

const router = useRouter()
const routeIn = useRoute()

const logout = () => {
  dispatch('user/logout').then(() => {
    msg.success('退出登录成功！', () => {
      router.push(`/login?redirect=${routeIn.path}`)
    })
  })
}

const formRef = ref(null)
const resetPasswordRules = {
  password: [
    { required: true, trigger: 'change', message: '请输入新的登录密码！' },
    { type: 'string', min: 6, trigger: 'change', message: '密码长度不能小于6！' }
  ],
  password_confirmation: [
    { required: true, trigger: 'change', message: '请再次确认登录密码！' },
    { type: 'string', min: 6, trigger: 'change', message: '密码长度不能小于6！' }
  ],
  oldPassword: [{ required: true, trigger: 'change', message: '请输入原登录密码！' }]
}
const resetPasswordDataInit = () => {
  return {
    phone: state.user.adminInfo.phone,
    oldPassword: '',
    password: '',
    password_confirmation: ''
  }
}
const resetPasswordData = ref(resetPasswordDataInit())
const resetPasswordVisible = ref(false)

const passwordReset = () => {
  resetPasswordVisible.value = true
  resetPasswordData.value = resetPasswordDataInit()
}

const passwordResetConfirm = async (formRef) => {
  if (!formRef) return
  await formRef.validate((valid, fields) => {
    if (valid) {
      if (resetPasswordData.value.password !== resetPasswordData.value.password_confirmation) {
        msg.error('两次输入的密码不正确！')
        return false
      }
      dispatch('user/resetPassword', resetPasswordData.value).then(() => {
        msg.success('密码修改成功，请用新密码重新登录！', () => {
          router.push('/login')
        })
      }).catch(() => {
      })
    }
  })
}
</script>

<style lang="scss">
@import '../assets/styles/layout.scss';
</style>
