
import * as Cache from '@/utils/tools/cache'
import axios from 'axios'

const getClientIp = () => {
  const ip = Cache.get('client-ip')
  if (ip) {
    return ip
  } else {
    return axios.get('https://api.ipify.org?format=json').then(response => {
      Cache.set('client-ip', response.data.ip)
      return response.data.ip
    })
  }
}

export default getClientIp
